<template>
  <v-app>
    <v-navigation-drawer
      app
      :expand-on-hover="$vuetify.breakpoint.smAndDown ? false : true"
      :clipped="$vuetify.breakpoint.mdAndUp"
      v-model="drawer"
      v-if="logueado"
      :mini-variant="$vuetify.breakpoint.smAndDown ? false : true"
      mini-variant-width="100"
      width="300"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            {{ this.$store.state.usuario.nombre }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list nav class="pt-0">
        <v-list-item
          :to="{ name: 'bienvenida' }"
          exact
          v-if="$vuetify.breakpoint.smAndDown ? true : false"
        >
          <v-list-item-action class="mr-1">
            <i class="fa-solid fa-house"></i>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title> Inicio </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{ name: 'estadisticas' }"
          v-if="permisoEstadisticas"
          active-class="blue--text"
        >
          <v-list-item-action class="mr-1">
            <i class="fa-solid fa-chart-simple"></i>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title> Estadisticas </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{ name: 'cupos' }"
          v-if="permisoCupo"
          active-class="blue--text"
        >
          <v-list-item-action class="mr-1">
            <i class="fa-solid fa-calendar-days"></i>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title> Registro de cupos </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{ name: 'citas' }"
          v-if="permisoCita"
          active-class="blue--text"
        >
          <v-list-item-action class="mr-1">
            <i class="fa-solid fa-calendar-check"></i>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title> Citas registradas </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{ name: 'medicos' }"
          v-if="permisoMedico"
          active-class="blue--text"
        >
          <v-list-item-action class="mr-1">
            <i class="fa-solid fa-user-doctor"></i>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title> Médicos </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{ name: 'pacientes' }"
          v-if="permisoPaciente"
          active-class="blue--text"
        >
          <v-list-item-action class="mr-1">
            <i class="fa-solid fa-id-card"></i>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title> Pacientes </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{ name: 'consultorio' }"
          v-if="permisoConsultorio"
          active-class="blue--text"
        >
          <v-list-item-action class="mr-1">
            <i class="fa-solid fa-hand-holding-medical"></i>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title> Centros médicos </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{ name: 'bitacora' }"
          v-if="permisoBitacora"
          active-class="blue--text"
        >
          <v-list-item-action class="mr-1">
            <i class="fa-solid fa-clipboard-list"></i>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title> Bitacora </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{ name: 'busqueda' }"
          v-if="permisoBusqueda"
          active-class="blue--text"
        >
          <v-list-item-action class="mr-1">
            <i class="fa-solid fa-magnifying-glass"></i>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title> Busqueda </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{ name: 'vistaCitas' }"
          v-if="permisoVistaCitas"
          active-class="blue--text"
        >
          <v-list-item-action class="mr-1">
            <i class="fa-solid fa-users-viewfinder"></i>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title> Citas por fecha de paciente </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{ name: 'EstadisticasCitas' }"
          active-class="blue--text"
          v-if="
            $store.state.usuario.nombre == 'Dr. Roberto Zelaya' ||
            $store.state.usuario.nombre == 'User Devco'
          "
        >
          <v-list-item-action class="mr-1">
            <i class="fa-solid fa-file-lines"></i>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title> Estadisticos citas </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{ name: 'Ordenamiento' }"
          active-class="blue--text"
          v-if="
            $store.state.usuario.nombre == 'Dr. Roberto Zelaya' ||
            $store.state.usuario.nombre == 'Cristina Hernandez' ||
            $store.state.usuario.nombre == 'User Devco'
          "
        >
          <v-list-item-action class="mr-1">
            <i class="fa-solid fa-file-lines"></i>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title> Ordenamiento </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <template v-if="permisoReporte">
          <v-list-group>
            <v-list-item slot="activator" active-class="blue--text">
              <v-list-item-content>
                <v-list-item-title> Reportes </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              :to="{ name: 'reporte_citas' }"
              active-class="blue--text"
            >
              <v-list-item-action class="mr-1">
                <i class="fa-solid fa-file-lines"></i>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Citas por fecha de creación
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>

        <template v-if="permisoUsuario">
          <v-list-group active-class="blue--text">
            <v-list-item slot="activator">
              <v-list-item-content>
                <v-list-item-title> Accesos </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'usuarios' }">
              <v-list-item-action class="mr-1">
                <i class="fa-solid fa-users"></i>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Cuentas </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item :to="{ name: 'roles' }">
              <v-list-item-action class="mr-1">
                <i class="fa-solid fa-user-gear"></i>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Roles </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item :to="{ name: 'modulos' }" v-if="false">
              <v-list-item-action class="mr-1">
                <i class="fa-solid fa-bars-staggered"></i>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Modulos </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      :clipped-left="$vuetify.breakpoint.mdAndUp"
      app
      color="primary"
      height="100"
    >
      <v-toolbar-title>
        <v-app-bar-nav-icon
          @click.stop="drawer = !drawer"
          v-if="logueado"
          class="white--text"
        ></v-app-bar-nav-icon>
      </v-toolbar-title>
      <v-img
        class="mx-2"
        v-if="$vuetify.breakpoint.mdAndUp ? true : false"
        :src="require('@/assets/logo_2.png')"
        max-width="100"
        max-height="100"
        contain
        @click="irPantallaPrincipal()"
      />
      <v-toolbar-title @click="irPantallaPrincipal()" class="white--text">
        Sistema Citas
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-tooltip bottom v-if="status_version_sistema">
        <template v-slot:activator="{ on, attrs }">
          <v-badge class="mr-5" color="error" content="!">
            <v-icon
              color="white"
              v-bind="attrs"
              v-on="on"
              @click="actualizarPagina()"
            >
              fa-solid fa-rotate-right
            </v-icon>
          </v-badge>
        </template>
        <span>Hay cambios en el sistema, favor de actualizar</span>
      </v-tooltip>

      <v-btn color="info" v-if="logueado" @click="cerrarSesion()">
        CERRAR SESION
        <v-icon right> fa-solid fa-right-from-bracket </v-icon>
      </v-btn>
    </v-app-bar>

    <v-footer app padless color="primary" height="50" v-if="true">
      <v-row justify="end" class="ma-0" no-gutters>
        <v-col cols="auto" class="pa-0">
          <v-list-item>
            <v-list-item-avatar class="mx-auto">
              <v-icon color="white" small>fa-regular fa-copyright</v-icon>
            </v-list-item-avatar>
            <v-list-item-content class="white--text">
              {{ 2022 }}
            </v-list-item-content>
            <v-list-item-avatar class="mx-auto" v-if="false">
              <a href="https://www.devco.sv" target="_blank">
                <v-img
                  max-width="35"
                  max-height="35"
                  :src="require('@/assets/logo_devco.png')"
                >
                </v-img
              ></a>
            </v-list-item-avatar>
          </v-list-item>
        </v-col>
      </v-row>
    </v-footer>

    <v-main>
      <v-container fluid class="fill-height pa-0">
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import router from "./router";
import axios from "axios";

export default {
  data: () => ({
    flag: true,
    drawer: true,
    permisos: [],
    fechaToken: null,
    contador: 100,
    valor_token: [],
    status_version_sistema: false,
    id_contador_visita: 0,
    visitas_usuarios: 0,
    visitas_invitados: 0,
  }),
  components: {
    //
  },
  watch: {
    //
  },
  computed: {
    logueado() {
      if (this.$store.state.usuario) {
        this.nuevaVisitaUsuario();
        this.habilitarModulos();
        this.versionSistema();
        return this.$store.state.usuario;
      } else {
        this.nuevaVisitaInvitados();
        return false;
      }
    },
    permisoCupo() {
      if (this.permisos.length > 0) {
        var index = this.permisos.findIndex((object) => {
          return object.nombre_modulo == "CUPOS";
        });
        if (index != -1) {
          return true;
        }
      }
    },
    permisoCita() {
      if (this.permisos.length > 0) {
        var index = this.permisos.findIndex((object) => {
          return object.nombre_modulo == "CITAS";
        });
        if (index != -1) {
          return true;
        }
      }
    },
    permisoMedico() {
      if (this.permisos.length > 0) {
        var index = this.permisos.findIndex((object) => {
          return object.nombre_modulo == "MEDICOS";
        });
        if (index != -1) {
          return true;
        }
      }
    },
    permisoPaciente() {
      if (this.permisos.length > 0) {
        var index = this.permisos.findIndex((object) => {
          return object.nombre_modulo == "PACIENTES";
        });
        if (index != -1) {
          return true;
        }
      }
    },
    permisoConsultorio() {
      if (this.permisos.length > 0) {
        var index = this.permisos.findIndex((object) => {
          return object.nombre_modulo == "CONSULTORIOS";
        });
        if (index != -1) {
          return true;
        }
      }
    },
    permisoBitacora() {
      if (this.permisos.length > 0) {
        var index = this.permisos.findIndex((object) => {
          return object.nombre_modulo == "BITACORA";
        });
        if (index != -1) {
          return true;
        }
      }
    },
    permisoBusqueda() {
      if (this.permisos.length > 0) {
        var index = this.permisos.findIndex((object) => {
          return object.nombre_modulo == "BUSQUEDA";
        });
        if (index != -1) {
          return true;
        }
      }
    },
    permisoReporte() {
      if (this.permisos.length > 0) {
        var index = this.permisos.findIndex((object) => {
          return object.nombre_modulo == "REPORTES";
        });
        if (index != -1) {
          return true;
        }
      }
    },
    permisoUsuario() {
      if (this.permisos.length > 0) {
        var index = this.permisos.findIndex((object) => {
          return object.nombre_modulo == "USUARIOS";
        });
        if (index != -1) {
          return true;
        }
      }
    },
    permisoEstadisticas() {
      if (this.permisos.length > 0) {
        var index = this.permisos.findIndex((object) => {
          return object.nombre_modulo == "ESTADISTICAS";
        });
        if (index != -1) {
          return true;
        }
      }
    },
    permisoVistaCitas() {
      if (this.permisos.length > 0) {
        var index = this.permisos.findIndex((object) => {
          return object.nombre_modulo == "VISTA CITAS";
        });
        if (index != -1) {
          return true;
        }
      }
    },
  },
  created() {
    this.autoLogin();
    this.listarContadorVisitas();
    //console.log(this.$store.state.usuario);
  },
  methods: {
    irPantallaPrincipal() {
      router.push({ name: "bienvenida" }).catch(() => {});
    },
    habilitarModulos() {
      var interval = setInterval(() => {
        if (this.$store.state.usuario) {
          if (this.permisos.length > 0) {
            clearInterval(interval);
          } else {
            this.itemsPermisos(this.$store.state.usuario.id_rol);
          }
        }
      }, 1000);
    },
    itemsPermisos(id_rol) {
      axios
        .get(
          "api/Permisos/Listar/" +
            id_rol +
            "/" +
            this.$store.state.usuario.idUsuario
        )
        .then((response) => {
          this.permisos = response.data;
        });
    },
    autoLogin() {
      this.$store.dispatch("autoLogin");
    },
    guardarMedico() {
      axios
        .get(
          "api/Medicos/ListarxConsultorio/" +
            this.$store.state.usuario.id_consultorio
        )
        .then((response) => {
          this.$store.dispatch("guardarMedico", response.data[0].id_medico);

          //console.log(response.data);
        });
    },
    desencriptarToken(token_encriptado) {
      var CryptoJS = require("crypto-js");
      var decrypted = CryptoJS.AES.decrypt(
        token_encriptado,
        "secret key devco"
      );
      var textOriginal = decrypted.toString(CryptoJS.enc.Utf8);
      return textOriginal;
    },
    cerrarSesion() {
      if (this.$store.state.usuario) {
        axios
          .delete("api/Claves/Eliminar/" + this.$store.state.usuario.idUsuario)
          .then(() => {
            this.$store.dispatch("salir");
          });
      }
    },
    listarContadorVisitas() {
      axios.get("api/ContadorVisitas/Listar").then((response) => {
        if (this.$store.state.usuario) {
          this.nuevaVisitaUsuario(response.data[0]);
        } else {
          this.nuevaVisitaInvitados(response.data[0]);
        }
      });
    },
    nuevaVisitaInvitados(cont) {
      if (cont != undefined) {
        axios.put("api/ContadorVisitas/Actualizar", {
          id_contador_visita: cont.id_contador_visita,
          visitas_usuarios: cont.visitas_usuarios,
          visitas_invitados: parseInt(cont.visitas_invitados) + 1,
        });
      }
    },
    nuevaVisitaUsuario(cont) {
      if (cont != undefined) {
        axios.put("api/ContadorVisitas/Actualizar", {
          id_contador_visita: cont.id_contador_visita,
          visitas_usuarios: parseInt(cont.visitas_usuarios) + 1,
          visitas_invitados: cont.visitas_invitados,
        });
      }
    },
    actualizarPagina() {
      this.$router.go(0);
    },
    versionSistema() {
      var interval = setInterval(() => {
        axios.get("api/ServerStatus/Version/" + 3).then((response) => {
          if (response.data.estado != localStorage.getItem("v_system")) {
            //console.log("hay una nueva version");
            this.status_version_sistema = true;
            localStorage.setItem("v_system", response.data.estado);
            clearInterval(interval);
          }
        });
      }, 3600000);
    },
  },
};
</script>

<style>
@font-face {
  font-family: "AbrilFatface-Regular";
  src: local("AbrilFatface-Regular"),
    url(~@/assets/fonts/AbrilFatface-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "AlfaSlabOne-Regular";
  src: local("AlfaSlabOne-Regular"),
    url(~@/assets/fonts/AlfaSlabOne-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "ChakraPetch-Light";
  src: local("ChakraPetch-Light"),
    url(~@/assets/fonts/ChakraPetch-Light.ttf) format("truetype");
}
</style>