import Vue from 'vue'
import Store from '../store'
import VueRouter from 'vue-router'
import PaginaNoEncontrada from "../components/PaginaNoEncontrada"
import Bienvenida from '../components/Bienvenida'
import InicioSesion from '../components/InicioSesion'
import CalendarioCita from '../components/CalendarioCitas'
import Pacientes from '../components/Pacientes'
import Medicos from '../components/Medicos'
import ReservarCita from '../components/ReservarCita'
import ReservarCitaGrupo from "../components/ReservarCitaGrupo";
import CalendarioCupos from '../components/CalendarioCupos'
import Usuarios from '../components/Usuarios'
import Consultorio from "../components/Consultorios"
import DetalleCitaRegistrada from "../components/DetalleCitaRegistrada"
import ReporteCitas from "../components/ReporteCitas"
import Roles from "../components/Roles"
import Bitacora from "../components/Bitacora"
import Modulos from "../components/Modulos"
import Busqueda from "../components/Busqueda"
import Estadisticas from "../components/Estadisticas"
import VistaCitas from "../components/VistaCitas"
import EstadisticasCitas from "../components/EstadisticoCitas"
import Ordenamiento from "../components/Ordenamiento.vue"

Vue.use(VueRouter)

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: '*',
      name: 'paginaNoEncontrada',
      component: PaginaNoEncontrada,
    },
    {
      path: '/',
      name: 'bienvenida',
      component: Bienvenida,
    },
    {
      path: "/Ordenamiento",
      name: "Ordenamiento",
      component: Ordenamiento
    },
    {
      path: "/EstadisticasCitas",
      name: "EstadisticasCitas",
      component: EstadisticasCitas
    },
    {
      path: '/vistaCitas',
      name: 'vistaCitas',
      component: VistaCitas,
    },
    {
      path: '/detalles_cita_registrada',
      name: 'detalles_cita_registrada',
      component: DetalleCitaRegistrada,
    },
    {
      path: '/sesion',
      name: 'sesion',
      component: InicioSesion,
    },
    {
      path: '/citas',
      name: 'citas',
      component: CalendarioCita,
    },
    {
      path: '/pacientes',
      name: 'pacientes',
      component: Pacientes,
    },
    {
      path: '/medicos',
      name: 'medicos',
      component: Medicos,
    },
    {
      path: '/reservar_cita',
      name: 'reservar_cita',
      component: ReservarCita,
    },
    {
      path: '/reservar_cita_grupo',
      name: 'reservar_cita_grupo',
      component: ReservarCitaGrupo,
    },
    {
      path: '/cupos',
      name: 'cupos',
      component: CalendarioCupos,
    },
    {
      path: '/usuarios',
      name: 'usuarios',
      component: Usuarios,
    },
    {
      path: '/roles',
      name: 'roles',
      component: Roles,
    },
    {
      path: '/consultorio',
      name: 'consultorio',
      component: Consultorio,
    },
    {
      path: '/reporte_citas',
      name: 'reporte_citas',
      component: ReporteCitas,
    },
    {
      path: '/bitacora',
      name: 'bitacora',
      component: Bitacora,
    },
    {
      path: '/modulos',
      name: 'modulos',
      component: Modulos,
    },
    {
      path: '/busqueda',
      name: 'busqueda',
      component: Busqueda,
    },
    {
      path: '/estadisticas',
      name: 'estadisticas',
      component: Estadisticas,
    },
  ]
})

router.beforeEach((to, from, next) => {
  const lackRole = to.matched.some(route => {
    return route.meta.roles && !route.meta.roles.include(Store.state.usuario.rol)
  })
  if(lackRole){
    return next(false);
  }
  next();
});

export default router
