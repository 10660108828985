<template>
  <v-row class="fill-height pa-2">
    <v-col cols="12">
      <v-row no-gutters>
        <v-col cols="auto" class="pa-1">
          <v-text-field
            v-model="desde"
            label="Desde"
            outlined
            dense
            hide-details
            type="date"
          ></v-text-field>
        </v-col>
        <v-col cols="auto" class="pa-1">
          <v-text-field
            v-model="hasta"
            label="Hasta"
            outlined
            dense
            hide-details
            type="date"
          ></v-text-field>
        </v-col>
        <v-col cols="auto" class="pa-1">
          <v-autocomplete
            v-model="dia_semana"
            :items="items_dias"
            item-text="texto"
            item-value="cod"
            label="Días"
            outlined
            dense
            hide-details
            @input="listarPorDia()"
          ></v-autocomplete>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto" class="pa-1" align-self="center">
          <v-btn
            color="primary"
            :loading="btn_loading"
            @click="guardarCambios()"
            >Guardar Cambios</v-btn
          >
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </v-col>
    <v-col cols="2" class="mx-auto">
      <div class="title">Dr. Zelaya - Total: {{ dr_zelaya.length }}</div>
      <draggable
        id="10"
        v-model="dr_zelaya"
        :sort="true"
        class="list-group"
        v-bind="dragOptions"
        @change="log"
      >
        <div
          v-for="item in dr_zelaya"
          :key="item.id_paciente"
          class="list-group-item style-list-table caption"
        >
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                v-bind="attrs"
                v-on="on"
                v-if="item.es_grupal_individual"
                >fa-solid fa-users</v-icon
              >
              <v-icon small v-bind="attrs" v-on="on" v-else
                >fa-solid fa-user</v-icon
              >
            </template>
            <span v-if="item.es_grupal_individual">GRUPAL</span>
            <span v-else>INDIVIDUAL</span>
          </v-tooltip>
          {{ item.nombre_paciente + " " + item.apellido_paciente }}
        </div>
      </draggable>
    </v-col>
    <v-col cols="2" class="mx-auto">
      <div class="title">Dr. Guillen - Total: {{ dr_guillen.length }}</div>
      <draggable
        id="14"
        v-model="dr_guillen"
        :sort="true"
        class="list-group"
        v-bind="dragOptions"
        @change="log"
      >
        <div
          v-for="item in dr_guillen"
          :key="item.id_paciente"
          class="list-group-item style-list-table caption"
        >
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                v-bind="attrs"
                v-on="on"
                v-if="item.es_grupal_individual"
                >fa-solid fa-users</v-icon
              >
              <v-icon small v-bind="attrs" v-on="on" v-else
                >fa-solid fa-user</v-icon
              >
            </template>
            <span v-if="item.es_grupal_individual">GRUPAL</span>
            <span v-else>INDIVIDUAL</span>
          </v-tooltip>
          {{ item.nombre_paciente + " " + item.apellido_paciente }}
        </div>
      </draggable>
    </v-col>
    <v-col cols="2" class="mx-auto">
      <div class="title">Dra. Bichara - Total: {{ dra_bichara.length }}</div>
      <draggable
        id="31"
        v-model="dra_bichara"
        :sort="true"
        class="list-group"
        v-bind="dragOptions"
        @change="log"
      >
        <div
          v-for="item in dra_bichara"
          :key="item.id_paciente"
          class="list-group-item style-list-table caption"
        >
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                v-bind="attrs"
                v-on="on"
                v-if="item.es_grupal_individual"
                >fa-solid fa-users</v-icon
              >
              <v-icon small v-bind="attrs" v-on="on" v-else
                >fa-solid fa-user</v-icon
              >
            </template>
            <span v-if="item.es_grupal_individual">GRUPAL</span>
            <span v-else>INDIVIDUAL</span>
          </v-tooltip>
          {{ item.nombre_paciente + " " + item.apellido_paciente }}
        </div>
      </draggable>
    </v-col>
    <v-col cols="2" class="mx-auto">
      <div class="title">Dra. Herrara - Total: {{ dra_herrera.length }}</div>
      <draggable
        id="29"
        v-model="dra_herrera"
        :sort="true"
        class="list-group"
        v-bind="dragOptions"
        @change="log"
      >
        <div
          v-for="item in dra_herrera"
          :key="item.id_paciente"
          class="list-group-item style-list-table caption"
        >
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                v-bind="attrs"
                v-on="on"
                v-if="item.es_grupal_individual"
                >fa-solid fa-users</v-icon
              >
              <v-icon small v-bind="attrs" v-on="on" v-else
                >fa-solid fa-user</v-icon
              >
            </template>
            <span v-if="item.es_grupal_individual">GRUPAL</span>
            <span v-else>INDIVIDUAL</span>
          </v-tooltip>
          {{ item.nombre_paciente + " " + item.apellido_paciente }}
        </div>
      </draggable>
    </v-col>
    <v-col cols="2" class="mx-auto">
      <div class="title">Dra. Chica - Total: {{ dra_chica.length }}</div>
      <draggable
        id="30"
        v-model="dra_chica"
        :sort="true"
        class="list-group"
        v-bind="dragOptions"
        @change="log"
      >
        <div
          v-for="item in dra_chica"
          :key="item.id_paciente"
          class="list-group-item style-list-table caption"
        >
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                v-bind="attrs"
                v-on="on"
                v-if="item.es_grupal_individual"
                >fa-solid fa-users</v-icon
              >
              <v-icon small v-bind="attrs" v-on="on" v-else
                >fa-solid fa-user</v-icon
              >
            </template>
            <span v-if="item.es_grupal_individual">GRUPAL</span>
            <span v-else>INDIVIDUAL</span>
          </v-tooltip>
          {{ item.nombre_paciente + " " + item.apellido_paciente }}
        </div>
      </draggable>
    </v-col>
    <v-col cols="2" class="mx-auto">
      <div class="title">Dra. Mancia - Total: {{ dra_mancia.length }}</div>
      <draggable
        id="32"
        v-model="dra_mancia"
        :sort="true"
        class="list-group"
        v-bind="dragOptions"
        @change="log"
      >
        <div
          v-for="item in dra_mancia"
          :key="item.id_paciente"
          class="list-group-item style-list-table caption"
        >
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                v-bind="attrs"
                v-on="on"
                v-if="item.es_grupal_individual"
                >fa-solid fa-users</v-icon
              >
              <v-icon small v-bind="attrs" v-on="on" v-else
                >fa-solid fa-user</v-icon
              >
            </template>
            <span v-if="item.es_grupal_individual">GRUPAL</span>
            <span v-else>INDIVIDUAL</span>
          </v-tooltip>
          {{ item.nombre_paciente + " " + item.apellido_paciente }}
        </div>
      </draggable>
    </v-col>
  </v-row>
</template>

<script>
import draggable from "vuedraggable";
import axios from "axios";
import moment from "moment";

export default {
  data: () => ({
    dr_zelaya: [],
    dr_guillen: [],
    dra_chica: [],
    dra_herrera: [],
    dra_bichara: [],
    dra_mancia: [],
    temp_items: [],
    desde: "",
    hasta: "",
    btn_loading: false,
    items_dias: [
      { cod: -1, texto: "VER TODOS" },
      { cod: 0, texto: "LUNES" },
      { cod: 1, texto: "MARTES" },
      { cod: 2, texto: "MIERCOLES" },
      { cod: 3, texto: "JUEVES" },
      { cod: 4, texto: "VIERNES" },
    ],
    dia_semana: -1,
  }),
  components: {
    draggable,
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  created() {
    this.listarCitasPorFecha();
  },
  methods: {
    listarCitasPorFecha() {
      var today = new Date();

      var dateCopy = new Date(today.getTime());
      this.desde = this.formatoFecha(
        new Date(
          dateCopy.setDate(
            dateCopy.getDate() + ((7 - dateCopy.getDay() + 1) % 7 || 7)
          )
        )
      );
      this.hasta = this.formatoFecha(
        new Date(
          dateCopy.setDate(
            dateCopy.getDate() + ((7 - dateCopy.getDay() + 5) % 7 || 7)
          )
        )
      );
      this.limpiarListas();

      axios
        .get("api/Citas/ListarTodos/" + this.desde + "/" + this.hasta)
        .then((response) => {
          //console.log(response.data);
          this.temp_items = response.data;
          for (var i = 0; i < this.temp_items.length; i++) {
            switch (this.temp_items[i].id_medico) {
              case 10:
                this.dr_zelaya.push(this.temp_items[i]);
                break;
              case 14:
                this.dr_guillen.push(this.temp_items[i]);
                break;
              case 29:
                this.dra_herrera.push(this.temp_items[i]);
                break;
              case 30:
                this.dra_chica.push(this.temp_items[i]);
                break;
              case 31:
                this.dra_bichara.push(this.temp_items[i]);
                break;
              case 32:
                this.dra_mancia.push(this.temp_items[i]);
                break;
            }
          }
        });
    },
    listarPorDia() {
      if (this.dia_semana == -1) {
        this.listarCitasPorFecha();
        return;
      }

      var initial = new Date(this.desde + " 00:00:00");
      var dia_semana = initial.setDate(initial.getDate() + this.dia_semana);
      var temp_fecha = this.formatoFecha(new Date(dia_semana));

      axios
        .get("api/Citas/ListarTodos/" + temp_fecha + "/" + temp_fecha)
        .then((response) => {
          //console.log(response.data);
          this.limpiarListas();
          this.temp_items = response.data;
          for (var i = 0; i < this.temp_items.length; i++) {
            switch (this.temp_items[i].id_medico) {
              case 10:
                this.dr_zelaya.push(this.temp_items[i]);
                break;
              case 14:
                this.dr_guillen.push(this.temp_items[i]);
                break;
              case 29:
                this.dra_herrera.push(this.temp_items[i]);
                break;
              case 30:
                this.dra_chica.push(this.temp_items[i]);
                break;
              case 31:
                this.dra_bichara.push(this.temp_items[i]);
                break;
              case 32:
                this.dra_mancia.push(this.temp_items[i]);
                break;
            }
          }
        });
    },
    async guardarCambios() {
      for (var i = 0; i < this.dr_zelaya.length; i++) {
        //id_medico 10
        if (this.dr_zelaya[i].id_medico != 10) {
          //console.log(this.dr_zelaya[i]);
          await axios.put(
            "api/Citas/ReordenarCita/" + this.dr_zelaya[i].id_cita + "/" + 10
          );
          await this.enviarCorreoPaciente(
            this.dr_zelaya[i].correo_paciente,
            this.dr_zelaya[i].nombre_paciente,
            this.dr_zelaya[i].apellido_paciente,
            "Dr. Roberto Zelaya"
          );
          await this.bitacora(
            "Se hizo movimiento de cita de los IDS: " + this.dr_zelaya[i].id_medico + " -> " + 10,
            this.dr_zelaya[i].fecha_cita.split("T")[0],
            this.dr_zelaya[i].id_medico,
            this.dr_zelaya[i].id_paciente
          );
        }
      }
      for (var i = 0; i < this.dr_guillen.length; i++) {
        //id_medico 14
        if (this.dr_guillen[i].id_medico != 14) {
          //console.log(this.dr_guillen[i]);
          await axios.put(
            "api/Citas/ReordenarCita/" + this.dr_guillen[i].id_cita + "/" + 14
          );
          await this.enviarCorreoPaciente(
            this.dr_guillen[i].correo_paciente,
            this.dr_guillen[i].nombre_paciente,
            this.dr_guillen[i].apellido_paciente,
            "Dr. Juan Guillen"
          );
          await this.bitacora(
            "Se hizo movimiento de cita de los IDS: " + this.dr_guillen[i].id_medico + " -> " + 14,
            this.dr_guillen[i].fecha_cita.split("T")[0],
            this.dr_guillen[i].id_medico,
            this.dr_guillen[i].id_paciente
          );
        }
      }
      for (var i = 0; i < this.dra_bichara.length; i++) {
        //id_medico 31
        if (this.dra_bichara[i].id_medico != 31) {
          await axios.put(
            "api/Citas/ReordenarCita/" + this.dra_bichara[i].id_cita + "/" + 31
          );
          await this.enviarCorreoPaciente(
            this.dra_bichara[i].correo_paciente,
            this.dra_bichara[i].nombre_paciente,
            this.dra_bichara[i].apellido_paciente,
            "Dra. Frida Bichara"
          );
          await this.bitacora(
            "Se hizo movimiento de cita de los IDS: " + this.dra_bichara[i].id_medico + " -> " + 31,
            this.dra_bichara[i].fecha_cita.split("T")[0],
            this.dra_bichara[i].id_medico,
            this.dra_bichara[i].id_paciente
          );
        }
      }
      for (var i = 0; i < this.dra_herrera.length; i++) {
        //id_medico 29
        if (this.dra_herrera[i].id_medico != 29) {
          await axios.put(
            "api/Citas/ReordenarCita/" + this.dra_herrera[i].id_cita + "/" + 29
          );
          await this.enviarCorreoPaciente(
            this.dra_herrera[i].correo_paciente,
            this.dra_herrera[i].nombre_paciente,
            this.dra_herrera[i].apellido_paciente,
            "Dra. María José Herrera"
          );
          await this.bitacora(
            "Se hizo movimiento de cita de los IDS: " + this.dra_herrera[i].id_medico + " -> " + 29,
            this.dra_herrera[i].fecha_cita.split("T")[0],
            this.dra_herrera[i].id_medico,
            this.dra_herrera[i].id_paciente
          );
        }
      }
      for (var i = 0; i < this.dra_chica.length; i++) {
        //id_medico 30
        if (this.dra_chica[i].id_medico != 30) {
          await axios.put(
            "api/Citas/ReordenarCita/" + this.dra_chica[i].id_cita + "/" + 30
          );
          await this.enviarCorreoPaciente(
            this.dra_chica[i].correo_paciente,
            this.dra_chica[i].nombre_paciente,
            this.dra_chica[i].apellido_paciente,
            "Dra. Margarita Chica"
          );
          await this.bitacora(
            "Se hizo movimiento de cita de los IDS: " + this.dra_chica[i].id_medico + " -> " + 30,
            this.dra_chica[i].fecha_cita.split("T")[0],
            this.dra_chica[i].id_medico,
            this.dra_chica[i].id_paciente
          );
        }
      }
      for (var i = 0; i < this.dra_mancia.length; i++) {
        //id_medico 32
        if (this.dra_mancia[i].id_medico != 32) {
          await axios.put(
            "api/Citas/ReordenarCita/" + this.dra_mancia[i].id_cita + "/" + 32
          );
          await this.enviarCorreoPaciente(
            this.dra_mancia[i].correo_paciente,
            this.dra_mancia[i].nombre_paciente,
            this.dra_mancia[i].apellido_paciente,
            "Dra. Magdalena Mancia"
          );
          await this.bitacora(
            "Se hizo movimiento de cita de los IDS: " + this.dra_mancia[i].id_medico + " -> " + 32,
            this.dra_mancia[i].fecha_cita.split("T")[0],
            this.dra_mancia[i].id_medico,
            this.dra_mancia[i].id_paciente
          );
        }
      }

      this.alerta("Proceso terminado", "info");

      if (this.dia_semana == -1) {
        this.listarCitasPorFecha();
      } else {
        this.listarPorDia();
      }
    },
    async enviarCorreoPaciente(correo, nombre, apellido, nombre_medico) {
      await axios.post("api/Citas/EnviarCorreo", {
        cod_mail: 1,
        correo_paciente: correo,
        nombre_paciente: nombre + " " + apellido,
        correo_medico: "",
        nombre_medico: nombre_medico,
        plantilla: "",
        template: 12,
        key_param: "nuevo_medico",
      });
    },
    async bitacora(descripcion, fecha_evento, id_medico, paciente) {
      var today = new Date();
      var fecha =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();

      await axios.post("api/Bitacoras/Crear", {
        id_usuario: this.$store.state.usuario.idUsuario,
        fecha: fecha,
        descripcion: descripcion,
        fecha_evento: fecha_evento,
        medico_asignado: id_medico,
        paciente: paciente,
        n_cupos: -1,
      });
    },
    log(item) {
      //console.log(item);
    },
    limpiarListas() {
      this.dr_zelaya = [];
      this.dr_guillen = [];
      this.dra_bichara = [];
      this.dra_chica = [];
      this.dra_herrera = [];
      this.dra_mancia = [];
    },
    formatoFecha(item) {
      return moment(item).format("YYYY-MM-DD");
    },
    alerta(texto, tipo_alerta) {
      this.$swal({
        toast: true,
        position: "top-end",
        title: texto,
        icon: tipo_alerta,
        showConfirmButton: false,
        timer: 0,
        backdrop: false,
      });
    },
  },
};
</script>

<style>
.style-list-table {
  border-left: 1px solid #e9ecef;
  border-right: 1px solid #e9ecef;
  border-top: 1px solid #e9ecef;
  border-bottom: 1px solid #e9ecef;
  padding: 5px;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
</style>